module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gleanbean Co., Ltd.","short_name":"gleanbean.co.jp","start_url":"/","background_color":"#23F52A","theme_color":"#23F52A","display":"minimal-ui","icon":"/home/ec2-user/environment/gleanbean.co.jp/src/images/gleanbean_logo_v20181210.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d596a88b01de1bf8782d2ad8fc7831d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
